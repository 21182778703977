import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = ({ isAuthenticated, userRole }) => {
  return (
    <nav className="bg-blue-600 p-4 shadow-lg">
      <div className="container mx-auto flex justify-between items-center">
        <Link className="text-white text-lg font-bold" to="/">Jarchi</Link>
        <ul className="flex space-x-4">
          {isAuthenticated ? (
            <>
              <li>
                <Link className="text-white hover:text-gray-200" to="/dashboard">Nazoratxona</Link>
              </li>
              <li>
                <Link className="text-white hover:text-gray-200" to="/settings">Sozlamalar</Link>
              </li>
              <li>
                <button 
                  className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                  onClick={() => {
                    localStorage.removeItem('token');
                    window.location.reload();
                  }}
                >
                  Logout
                </button>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link className="text-white hover:text-gray-200" to="/login">Kirish</Link>
              </li>
              <li>
                <Link className="text-white hover:text-gray-200" to="/register">Yozilish</Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
