import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../services/api';
import StatusIndicator from '../components/StatusIndicator';

const RequestDetails = () => {
  const { requestId } = useParams();
  const [request, setRequest] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRequest = async () => {
      try {
        const response = await api.get(`/advertisers/requests/${requestId}`);
        setRequest(response.data);
      } catch (err) {
        setError('Failed to load request details.');
      }
    };
    fetchRequest();
  }, [requestId]);

  if (error) return <p>{error}</p>;
  if (!request) return <p>Loading...</p>;

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-2xl font-bold mb-4">{request.title}</h1>
      <p>{request.description}</p>
      <p>Quantity: {request.quantity}</p>

      <h2 className="text-xl font-bold mt-6 mb-2">Status</h2>
      <StatusIndicator currentStatus={request.status} />
      {request.flyerImage && (
        <img
          src={`${process.env.REACT_APP_UPLOADS_URL}/${request.flyerImage}`}
          alt="Flyer Design"
          className="w-full h-auto rounded mt-4"
        />
      )}
    </div>
  );
};

export default RequestDetails;
