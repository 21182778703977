import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Register from './pages/Register';
import Login from './pages/Login';
import Settings from './pages/Settings';
import AdvertiserDashboard from './pages/AdvertiserDashboard';
import RequestDetails from './pages/RequestDetails'; // Import the RequestDetails component
import PrivateRoute from './components/PrivateRoute';
import Navbar from './components/Navbar';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    // Check if user is logged in and get role from localStorage
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
      
      // Decode the token to get the user's role
      const payload = JSON.parse(atob(token.split('.')[1]));
      setUserRole(payload.role);
    }
  }, []);

  return (
    <Router>
      <Navbar isAuthenticated={isAuthenticated} userRole={userRole} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <AdvertiserDashboard />
            </PrivateRoute>
          }
        />
        
        <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />

        {/* New Route for Individual Request Pages */}
        <Route 
          path="/advertiser/requests/:requestId" 
          element={
            <PrivateRoute>
              <RequestDetails />
            </PrivateRoute>
          } 
        />
      </Routes>
    </Router>
  );
};

export default App;
