import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Polygon, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import {Icon} from 'leaflet'

const RequestForm = ({
    onSubmit,
    editingRequest,
    initialData = { title: '', description: '', quantity: '', flyerImage: null, mapType: 'spot', mapData: null },
}) => {
    const [formData, setFormData] = useState(initialData);
    const [selectedFile, setSelectedFile] = useState(null);
    const [mapType, setMapType] = useState(initialData.mapType || 'spot');
    const [mapData, setMapData] = useState(initialData.mapData || null);

    useEffect(() => {
        if (editingRequest) {
            setFormData({
                title: editingRequest.title,
                description: editingRequest.description,
                quantity: editingRequest.quantity,
            });
            setMapType(editingRequest.area ? 'area' : 'spot'); // Determine the map type
            setMapData(editingRequest.area || editingRequest.location || null); // Use area or location
        }
    }, [editingRequest]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const submissionData = new FormData(); // Create a FormData instance
        submissionData.append('title', formData.title); // Access the state
        submissionData.append('description', formData.description);
        submissionData.append('quantity', formData.quantity);
        if (selectedFile) {
            submissionData.append('flyerImage', selectedFile);
        }
        submissionData.append('mapType', mapType);
        if (mapData) {
            submissionData.append('mapData', JSON.stringify(mapData)); // Serialize map data
        }

        onSubmit(submissionData); // Pass FormData to the parent component
    };

    // Map interaction for setting spot or area
    const MapClickHandler = () => {
        useMapEvents({
            click: (e) => {
                if (mapType === 'spot') {
                    setMapData([e.latlng.lat, e.latlng.lng]); // Save the single marker's location
                } else if (mapType === 'area') {
                    if (!Array.isArray(mapData)) {
                        setMapData([]); // Initialize mapData if not already an array
                    }
                    setMapData((prev) => [...prev, [e.latlng.lat, e.latlng.lng]]); // Add polygon points
                }
            },
        });
        return null;
    };

    return (
        <form onSubmit={handleSubmit} className="mb-8" encType="multipart/form-data">
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
                    Title
                </label>
                <input
                    type="text"
                    id="title"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter the title"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                    Description
                </label>
                <textarea
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter the description"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="quantity">
                    Quantity
                </label>
                <input
                    type="number"
                    id="quantity"
                    name="quantity"
                    value={formData.quantity}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter the quantity"
                    required
                />
            </div>
            <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="flyerImage">
                    Upload Flyer Design
                </label>
                <input
                    type="file"
                    id="flyerImage"
                    name="flyerImage"
                    onChange={handleFileChange}
                    className="w-full"
                    accept="image/*"
                />
            </div>
            <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="mapType">
                    Distribution Location Type
                </label>
                <select
                    id="mapType"
                    value={mapType}
                    onChange={(e) => setMapType(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    <option value="spot">Single Spot</option>
                    <option value="area">Area</option>
                </select>
            </div>
            <div className="mb-6">
                <MapContainer center={[41.2995, 69.2401]} zoom={13} className="h-96">
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <MapClickHandler />
                    {mapType === 'spot' && mapData && (
                        <Marker position={mapData} icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}></Marker>
                    )}
                    {mapType === 'area' && Array.isArray(mapData) && (
                        <Polygon positions={mapData} />
                    )}
                </MapContainer>

            </div>
            <button
                type="submit"
                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
            >
                {editingRequest ? 'Update Request' : 'Submit Request'}
            </button>
        </form>
    );
};

export default RequestForm;
