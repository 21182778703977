import React, { useEffect, useState } from 'react';
import api from '../services/api';
import RequestForm from '../components/RequestForm';

const AdvertiserDashboard = () => {
    const [requests, setRequests] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [editingRequest, setEditingRequest] = useState(null);

    useEffect(() => {
        const fetchRequests = async () => {
            try {
                const response = await api.get('/advertisers/requests');
                setRequests(response.data);
                setLoading(false);
            } catch (err) {
                console.error('Failed to load requests:', err);
                setError('Failed to load requests');
                setLoading(false);
            }
        };

        fetchRequests();
    }, []);

    const handleCreateOrEditRequest = async (formData) => {
        try {
            let response;
            if (editingRequest) {
                response = await api.patch(`/advertisers/requests/${editingRequest.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            } else {
                response = await api.post('/advertisers/requests', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            }
    
            if (editingRequest) {
                setRequests(requests.map((req) => (req.id === editingRequest.id ? response.data : req)));
            } else {
                setRequests([...requests, response.data]);
            }
    
            setShowForm(false);
            setEditingRequest(null);
        } catch (err) {
            console.error('Failed to create or update request:', err);
            setError('Failed to create or update request');
        }
    };

    const handleEditClick = (request) => {
        setEditingRequest(request);
        setShowForm(true);
    };

    return (
        <div className="container mx-auto p-8">
            <h1 className="text-2xl font-bold mb-6">Advertiser Dashboard</h1>

            <button
                onClick={() => {
                    setShowForm(!showForm);
                    setEditingRequest(null);
                }}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mb-4"
            >
                {showForm ? 'Cancel' : 'Create Request'}
            </button>

            {showForm && (
                <RequestForm
                    onSubmit={handleCreateOrEditRequest}
                    editingRequest={editingRequest}
                />
            )}

            <h2 className="text-xl mb-4">Your Requests</h2>
            {loading && <p>Loading...</p>}
            {error && <p className="text-red-500">{error}</p>}

            {requests.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {requests.map((request, index) => (
                        <div key={index} className="bg-white shadow-md rounded p-4">
                            <h3 className="text-lg font-semibold">{request.title}</h3>
                            <p className="text-gray-700">{request.description}</p>
                            <p className="text-sm text-gray-500">Quantity: {request.quantity}</p>
                            <button
                                onClick={() => handleEditClick(request)}
                                className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 mt-2"
                            >
                                Edit
                            </button>
                        </div>
                    ))}
                </div>
            ) : (
                !loading && <p>No requests available.</p>
            )}
        </div>
    );
};

export default AdvertiserDashboard;
