import React from 'react';

const Home = () => {
  return (
    <div className="flex flex-col h-screen">

      {/* Main Content */}
      <main className="flex-grow flex flex-col items-center justify-center bg-gray-50">
        <section className="text-center">
          <h2 className="text-4xl font-bold text-blue-600 mb-4">E'lon Tarqatishda Inqilob</h2>
          <p className="text-lg text-gray-700 mb-6">Biznesingiz uchun dahmazasiz chop etish, reklama qilish va tarqatish xizmati.</p>
          <a
            href="/register"
            className="bg-blue-600 text-white px-6 py-3 rounded-md shadow-md hover:bg-blue-700 transition"
          >
            Ishga Kirishing
          </a>
        </section>
        <section className="container mx-auto mt-12 px-4 text-center">
          <h3 className="text-2xl font-bold mb-6">Qanday Ishlaydi</h3>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
            <div className="p-6 bg-white shadow rounded-md">
              <h4 className="text-xl font-semibold mb-3">Reklama Beruvchilarga</h4>
              <p>E'lon tarqatishni osongina boshqaring.</p>
            </div>
            <div className="p-6 bg-white shadow rounded-md">
              <h4 className="text-xl font-semibold mb-3">Bosmaxonalarga</h4>
              <p>E'longa buyurtmalarga sohib chiqing va chop eting.</p>
            </div>
            <div className="p-6 bg-white shadow rounded-md">
              <h4 className="text-xl font-semibold mb-3">Tarqatuvchilarga</h4>
              <p>Chop etilgan e'lonlarni tarqating va pul ishlang.</p>
            </div>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-4">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 Jarchi. All rights reserved.</p>
          <p className="text-sm">
            <a href="/about" className="hover:underline">Haqida</a> | 
            <a href="/contact" className="hover:underline">Aloqa</a> | 
            <a href="/privacy" className="hover:underline">Maxfiylik Siyosati</a>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Home;
