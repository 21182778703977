import React, { useState } from 'react';
import { registerUser } from '../services/api';

const Register = () => {
  const [selectedRole, setSelectedRole] = useState('Advertiser');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    role: selectedRole,
  });
  const [error, setError] = useState('');

  const handleRoleSelection = (role) => {
    setSelectedRole(role);
    setFormData({ ...formData, role });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await registerUser(formData);
      console.log('User registered successfully:', result);
      // Redirect or show success message
    } catch (err) {
      console.error('Registration failed:', err);
      setError(err.message || 'Registration failed');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
      <div className="bg-white shadow-md rounded-md p-8 w-full max-w-lg">
        <h1 className="text-3xl font-bold text-center mb-6 text-blue-600">Yozilish</h1>

        {/* Role Selection */}
        <div className="mb-8">
          <h2 className="text-lg font-medium mb-4">Kimsiz?</h2>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            {[
              { role: 'Reklama Beruvchi', description: 'Kampaniyalar yarating va boshqaring.' },
              { role: 'Bosmaxona', description: 'Buyurtmalar oling va chop eting.' },
              { role: 'Tarqatuvchi', description: 'Reklama tarqatishda qatnashing.' },
            ].map((item) => (
              <div
                key={item.role}
                onClick={() => handleRoleSelection(item.role)}
                className={`p-4 rounded-md cursor-pointer border-2 ${
                  selectedRole === item.role ? 'border-blue-600 bg-blue-50' : 'border-gray-300'
                } hover:border-blue-600`}
              >
                <h3 className="text-xl font-semibold text-center mb-2">{item.role}</h3>
                <p className="text-sm text-center text-gray-600">{item.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Display Errors */}
        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}

        {/* Registration Form */}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Nom
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-600 focus:border-blue-600"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-600 focus:border-blue-600"
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-600 focus:border-blue-600"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition"
          >
            {selectedRole} sifatida yoziling
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;
