import React from 'react';

// Define the stages of the request status here
const STATUS_STAGES = [
  'New',
  'Claimed by Printer',
  'Printing',
  'Printing Finished',
  'Delivered',
  'Claimed by Promoter',
  'Distributing',
  'Distributed',
  'In Review',
  'Completed'
];

const StatusIndicator = ({ currentStatus }) => {
  const currentStageIndex = STATUS_STAGES.indexOf(currentStatus);
  return (
    <div className="flex space-x-4 my-4">
      {STATUS_STAGES.map((status, index) => (
        <div key={status} className="flex flex-col items-center">
          <div
            className={`w-8 h-8 flex items-center justify-center rounded-full text-white ${
              index <= currentStageIndex ? 'bg-blue-600' : 'bg-gray-300'
            }`}
          >
            {index + 1}
          </div>
          <p
            className={`text-xs mt-2 ${
              index <= currentStageIndex ? 'text-blue-600' : 'text-gray-400'
            }`}
          >
            {status}
          </p>
        </div>
      ))}
    </div>
  );
};

export default StatusIndicator;
